@import "./_reset";

/* global css start */

// .slick-list .slick-track {
// 	@include transition(all .8s cubic-bezier(.65,.05,.18,.99) !important);
// }
.App {
	position: relative;
	overflow: hidden;
}

.nice-select {
	background-color: transparent;
	height: 60px !important;
	line-height: 55px !important;
	min-height: 60px !important;
	padding: 0 20px;
	width: 100%;
	&::after {
		border-bottom: 1.9px solid #B1B4BA;
		border-right: 1.9px solid #B1B4BA;
		height: 6px;
		right: 20px;
		width: 6px;
	}
	.option:hover, 
	.option.focus,
	.option.selected.focus {
		background-color: #EDF3F5;
	}
	.list {
		width: 100%;
		li {
			margin-right: 0 !important;
		}
		.option {
			color: var(--color-dark);
			font-weight: 500;
			&.selected {
				font-weight: 600;
			}
			&.selected,
			&:hover {
				border: none !important;
			}
		}
	}
}

/* global css end */
.body_wrap {
	overflow: hidden;
}

.bg_img{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.footer-bg {
	background-color: #04060A;
}

.black-bg {
	background: var(--color-black);
}
.gray-bg {
	background-color: var(--color-gray);
}
.gray-bg-2 {
	background-color: var(--color-gray-2);
}
.dark-bg {
	background-color: var(--color-dark);
}
.white {
  color: var(--color-white);
}
.pos-rel {
	position: relative;
}
.pos-absolute {
	position: absolute;
}
.f-right {
  float: right;
}

// border effect
.border-effect, .border-effect-2 {
	a {
		display: inline !important;
		width: 100%;
		background-repeat: no-repeat;
		background-position-y: -2px;
		background-image: linear-gradient(transparent calc(100% - 2px),currentColor 1px);
		@include transition(.6s cubic-bezier(.215,.61,.355,1));
		background-size: 0 100%;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		&:hover {
			background-size: 100% 100%;
			color: inherit;
		}
	}
}
.border-effect-2 {
	a {
		background-image: linear-gradient(transparent calc(100% - 1px),currentColor 1px);
	}
}


// video
.btn-video {
    width: 60px;
    height: 60px;
    @include border-radius(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0F172A;
	position: relative;
	background-color: var(--color-white);
	z-index: 1;
    &:hover {
        color: #0F172A;
    }
	&::before {
		content: "";
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.63);
		border-radius: 50%;
		animation: pulse-border 1500ms ease-out infinite;
		-webkit-animation: pulse-border 1500ms ease-out infinite;
		z-index: -2;
	}
}

// border effect
.border_effect {
	a {
		display: inline !important;
		width: 100%;
		background-repeat: no-repeat;
		background-position-y: -2px;
		background-image: linear-gradient(transparent calc(100% - 2px),currentColor 1px);
		transition: .6s cubic-bezier(.215,.61,.355,1);
		background-size: 0 100%;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		&:hover {
			background-size: 100% 100%;
			color: inherit;
		}
	}
}
.tx-col-md-6 {
	@include respond(md) {
		width: 50%;
	}
	@include respond(xs) {
		width: 100%;
	}
}

.xb-close {
	background: rgba(0,0,0,4%);
	border: 9px solid transparent;
	color: #777;
	width: 36px;
	height: 36px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	&::before,
	&::after {
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -1px;
		transform-origin: 50% 50%;
		-webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
		-khtml-transition: all .25s cubic-bezier(.645,.045,.355,1);
		-moz-transition: all .25s cubic-bezier(.645,.045,.355,1);
		-ms-transition: all .25s cubic-bezier(.645,.045,.355,1);
		-o-transition: all .25s cubic-bezier(.645,.045,.355,1);
		transition: all .25s cubic-bezier(.645,.045,.355,1);
		background-color: #1b1b1b;
	}
	&::before {
		-webkit-transform: rotate(45deg);
		-khtml-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	&::after {
		-webkit-transform: rotate(-45deg);
		-khtml-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	&:hover::before,
	&:hover::after {
		-webkit-transform: rotate(0);
		-khtml-transform: rotate(0);
		-moz-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}
}

/* order & unorder list reset - start */
.ul_li, .ul_li_right, .ul_li_center, .ul_li_between {
	margin: 0px;
	padding: 0px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.ul_li>li, .ul_li_right>li, .ul_li_center>li, .ul_li_between>li {
	float: left;
	list-style: none;
	display: inline-block;
}
.ul_li {
	justify-content: flex-start;
}
.ul_li_center {
	justify-content: center;
}
.ul_li_right {
	justify-content: flex-end;
}
.ul_li_between {
	justify-content: space-between;
}
.ul_li_block {
	margin: 0px;
	padding: 0px;
	display: block;
}
.ul_li_block > li {
	display: block;
	list-style: none;
}
.flex-1 {
	flex: 1;
}


// color
.color-black {
	color: var(--color-black);
}

// pagination
.pagination_wrap {
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: -5px;
		list-style: none;
		li {
			padding: 5px;
			a {
				height: 50px;
				width: 50px;
				line-height: 50px;
				text-align: center;
				font-size: 16px;
				font-weight: 600;
				color: #0F172A;
				border: 1px solid #EDF3F5;
				display: inline-block;
				@include transition(all 0.3s ease-out 0s);
				z-index: 1;
				@include border-radius(50%);
				overflow: hidden;
				&.current_page,
				&:hover {
					color: var(--color-white);
					background-color: var(--color-primary);
					border-color: var(--color-primary);
				}
			}
		}
	}
}

// margin none
.mr-none-60 {
	margin-right: -60px;
}
.ml-none-60 {
	margin-left: -60px;
}
// padding
.pb-8 {
	padding-bottom: 8px;
}